const icon = type => (type ? `ndl-icon-${type}` : null);
const isObject = o => Object.prototype.toString.call(o) === "[object Object]";

export default class Option {
  constructor(key, label, iconType, divider = false) {
    let initial = null;
    if (isObject(key)) {
      initial = key;
    } else {
      initial = { key, label, divider };
      initial.icon = icon(iconType);
    }
    Object.assign(this, initial);
  }
  $set(key, value) {
    const opt = new Option(this);
    opt[key] = value;
    return opt;
  }
  $key(value) {
    return this.$set("key", value);
  }
  $label(value) {
    return this.$set("label", value);
  }
  $icon(value, autoPrefix = true) {
    return this.$set("icon", autoPrefix ? icon(value) : value);
  }
  $value(value) {
    return this.$set("value", value);
  }
  $disabled(bool = true) {
    return this.$set("disabled", bool);
  }
  $checked(bool = true) {
    return this.$set("checked", bool);
  }
  $divider(bool = true) {
    return this.$set("divider", bool);
  }
  $iconClass(value) {
    return this.$set("iconClass", value);
  }
  $iconStyle(value) {
    return this.$set("iconStyle", value);
  }
  $labelClass(value) {
    return this.$set("labelClass", value);
  }
  $labelStyle(value) {
    return this.$set("labelStyle", value);
  }
  $children(value) {
    return this.$set("children", value);
  }
  $width(value) {
    return this.$set("width", value);
  }
  $listener(value) {
    return this.$set("listener", value);
  }

  /**
   * 若设置了 listener 可手动调用
   * @param {any} that listener 调用时的上下文
   * @param  {...any} params listener 调用的参数
   * @returns
   */
  onSelect(that = null, ...params) {
    const listener = this.listener;
    if (typeof listener === "function") {
      return listener.apply(that, params);
    }
    return false;
  }

  static iconOf(type) {
    return icon(type);
  }
}
