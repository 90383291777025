import showContext, { componentId } from "@/components/contextify";
import Contextmenu from "./src/Contextmenu";
import ContextOption from "./src/class-option";

export { Contextmenu, ContextOption };

/**
 * @param {{
 *  options: ContextOption[],
 *  width: number | string
 * }} props
 * @param {import("@/components/contextify").ContextOptions} opts contextify 参数
 * @returns {Promise<any>}
 */
export default function(props, opts = {}) {
  // contextmenu 默认使用透明度高的 context
  opts.light = opts.light !== false;
  return new Promise(resolve => {
    let vm = null;
    const onSelect = item => {
      resolve(item);
      if (vm) vm.hide();
    };
    // 为实现连续点击同一个元素时，第一次点击展开菜单，第二次点击
    // 收起菜单，出此下策。详见 contextify 组件实现相关实现逻辑。
    // eslint-disable-next-line no-unused-vars
    const Context = {
      render() {
        return <Contextmenu {...{ props }} onSelect={onSelect} />;
      }
    };
    componentId(Context, Symbol.for("contextmenu component"));
    vm = showContext(Context, opts);
  });
}
