<template>
  <ul class="contextmenu-wrapper" :style="{ width }">
    <template v-for="(item, index) in options">
      <!--
        @mousedown.stop 是因为 contextify 的 click-outside 依赖 mousedown 事件
        这里阻止了事件冒泡，click 的时候就不回导致子菜单收起 🤓
       -->
      <li
        :key="index"
        class="contextmenu-item ndl-flex ndl-flex--middle"
        :class="{ checked: item.checked, disabled: item.disabled }"
        @mousedown.stop
        @click="onClick(item)"
        @mouseenter.self="onMouseenter(item, $event)"
        @mouseleave="wait()"
      >
        <!-- icon -->
        <span
          v-if="item.icon"
          class="contextmenu-item--icon"
          :class="item.iconClass"
          :style="item.iconStyle"
        >
          <i :class="item.icon"></i>
        </span>
        <!-- label -->
        <span
          class="contextmenu-item--label ndl-flex-item--grow ndl-ellipsis"
          :class="item.labelClass"
          :style="item.labelStyle"
        >
          {{ item.label }}
        </span>
        <!-- checked -->
        <span v-if="item.checked" class="contextmenu-item--checked">
          <i class="ndl-icon-check"></i>
        </span>
        <!-- arrow: children indicator -->
        <span
          v-if="item.children"
          class="ndl-text-disabled contextmenu-item--arrow"
        >
          <i class="ndl-icon-chevron-right"></i>
        </span>
      </li>
      <!-- 分割线 -->
      <li
        v-if="item.divider"
        :key="'d_' + index"
        class="contextmenu-divider"
      ></li>
    </template>
  </ul>
</template>

<script>
import showContext from "@/components/contextify";
import Contextmenu from "./Contextmenu";

export default {
  props: {
    options: Array,
    width: String
  },
  inject: ["getContextConfig"],
  beforeDestroy() {
    this.destroy();
  },
  methods: {
    onClick(item) {
      if (item.children || item.disabled) {
        return;
      }
      if (item.onSelect) {
        item.onSelect(null, item);
      }
      this.$emit("select", item);
      this.destroy();
    },
    wait() {
      clearTimeout(this.__timer__);
      return new Promise(resolve => {
        this.__timer__ = setTimeout(resolve, 350);
      });
    },
    /**
     * 选中菜单项后有时会莫名弹出二级菜单，原因未知。
     * 故而在此添加一些清理组件的代码。
     * @todo debug
     */
    destroy() {
      clearTimeout(this.__timer__);
      const lastHoverItem = this._lastHoverItem;
      if (lastHoverItem && lastHoverItem._context) {
        lastHoverItem._context.hide();
        lastHoverItem._context = null;
        this._lastHoverItem = null;
      }
    },
    async onMouseenter(item, evt) {
      await this.wait();
      // this._lastHoverItem: 当前 contextmenu 最后 hover 的条目
      // item._context 菜单条目关联的子菜单 context
      // 原理是，同一时间只会激活一个菜单的子菜单
      // 鼠标 hover 时检查当前子菜单是否已经展开，已展开则不需要重复渲染子菜单
      // 若 _lastHoverItem 不等于 item，则说明需要重新渲染子菜单
      // 这时候要关闭上一个展开的菜单，并重置 _lastHoverItem
      const lastHoverItem = this._lastHoverItem;
      if (lastHoverItem === item) {
        return;
      }
      if (lastHoverItem && lastHoverItem._context) {
        lastHoverItem._context.hide();
        this._lastHoverItem = null;
      }
      if (!item.children || item.disabled) {
        return;
      }
      const onClick = item => {
        this.onClick(item);
        if (vm) {
          vm.hide();
        }
      };
      const parentContextConfig = this.getContextConfig();
      const vm = showContext(
        // ComponentDefinition
        {
          render() {
            const props = { options: item.children, width: item.width };
            return <Contextmenu {...{ props }} onSelect={onClick} />;
          }
        },
        // context options
        {
          ...parentContextConfig,
          source: evt.target,
          inverse: { y: true }
        }
      );
      item._context = vm;
      this._lastHoverItem = item;
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.contextmenu-wrapper {
  list-style: none;
  padding: 5px 0;
  margin: 0;
  width: 180px;
  min-width: 130px;
  font-size: 13px;
  @edge: 16px;
  .contextmenu-item {
    cursor: pointer;
    user-select: none;
    padding: 8px @edge;
    transition: background-color 0.2s, color 0.2s;
    &:hover {
      background-color: @primary-color-light;
    }
    &.checked {
      color: @primary-color;
    }
    &.disabled {
      color: @disabled-color;
      cursor: not-allowed;
      background: none;
    }
    &.disabled .contextmenu-item--icon,
    &.disabled .contextmenu-item--label,
    &.checked .contextmenu-item--icon {
      color: inherit !important;
    }
    &--icon {
      margin-right: 12px;
      transform: scale(1.12);
      color: @text-color-secondary;
      display: flex;
      align-content: center;
      justify-content: center;
    }
    &--checked,
    &--arrow {
      margin-left: 5px;
    }
  }
  .contextmenu-divider {
    margin: 5px @edge;
    height: 1px;
    background-color: @ndl-border-color;
  }
}
</style>
