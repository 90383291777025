const eventHandler = Symbol("EventHandler");

export default class Observer {
  constructor() {
    this[eventHandler] = {};
  }
  on(evtName, fn) {
    const map = this[eventHandler];
    if (!map[evtName]) map[evtName] = new Set();
    map[evtName].add(fn);
    return this;
  }
  off(evtName, fn) {
    if (!evtName) {
      this[eventHandler] = {};
      return this;
    }
    const map = this[eventHandler];
    if (!map[evtName]) return this;
    if (!fn) {
      delete map[evtName];
      return this;
    }
    map[evtName].delete(fn);
    return this;
  }
  emit(evtName, ...args) {
    const map = this[eventHandler];
    const listeners = map[evtName];
    if (!listeners) return this;
    for (const fn of listeners) {
      if (fn && fn.apply) fn.apply(this, args);
    }
    return this;
  }
}
